import { TTextStyledProps } from '@shared/ui'
import { theme } from '@shared/config'
import { TSize } from './types'

export const configTextStyle: Record<
  TSize,
  {
    fontSize: number
    lineHeight?: number
  }
> = {
  lg: {
    fontSize: theme.fonts.fontSizes.md,
    lineHeight: 32,
  },
  md: {
    fontSize: theme.fonts.fontSizes.default,
    lineHeight: theme.fonts.lineHeights.default,
  },
  sm: {
    fontSize: theme.fonts.fontSizes.xs,
    lineHeight: theme.fonts.lineHeights.default,
  },
}

export const getTextStyle = (size: TSize, sizemob: TSize): TTextStyledProps => {
  let style: TTextStyledProps = {
    fontSizeMob: configTextStyle[sizemob || size]?.fontSize,
    lineHeightMob: configTextStyle[sizemob || size]?.lineHeight,
  }

  if (sizemob && sizemob !== size) {
    style = {
      ...style,
      fontSizeSm: configTextStyle[size]?.fontSize,
      lineHeightSm: configTextStyle[size]?.lineHeight,
    }
  }

  return style
}
