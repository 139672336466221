import styled from 'styled-components'
import { theme } from '@shared/config'
import { Text } from '@shared/ui'

const OrderedLi = styled.li`
  width: 100%;
  margin-bottom: ${theme.spacings.xs2}px;
  list-style-type: none;
  display: grid;

  :last-child {
    margin-bottom: 0;
  }
`

const OrderedContent = styled.div`
  display: inline-block;
  grid-column: 3/4;
`

const InlineBlock = styled(Text)`
  display: inline-block;
  margin-right: ${theme.spacings.xxs}px;
`

export const Styled = {
  OrderedLi,
  OrderedContent,
  InlineBlock,
}
