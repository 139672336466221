import React from 'react'
import { TBulletType, TSize } from '../../types'
import { Styled } from './styled'

type TListBulletUnorderedProps = {
  children: React.ReactNode
  size?: TSize
  sizemob?: TSize
  bulletColor?: string
  bulletType?: TBulletType
}

export const ListBulletUnordered = ({
  children,
  size = 'md',
  sizemob,
  bulletColor,
  bulletType,
}: TListBulletUnorderedProps) => (
  <Styled.UnorderedWrapper size={size} sizemob={sizemob}>
    <Styled.UnorderedBulletWrapper>
      <Styled.UnorderedIcon size={size} sizemob={sizemob} bulletColor={bulletColor} bulletType={bulletType} />
    </Styled.UnorderedBulletWrapper>
    <Styled.UnorderedContent>{children}</Styled.UnorderedContent>
  </Styled.UnorderedWrapper>
)
