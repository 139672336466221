import React, { ReactNode } from 'react'
import { getTextStyle } from '../../config'
import { TSize } from '../../types'
import { Styled } from './styled'

type TListBulletOrderedProps = {
  children: ReactNode
  index: number | string
  size?: TSize
  sizemob?: TSize
}

export const ListBulletOrdered = ({ children, size, sizemob, index, ...rest }: TListBulletOrderedProps) => (
  <Styled.OrderedLi {...rest}>
    <Styled.InlineBlock {...getTextStyle(size, sizemob)}>{index}.</Styled.InlineBlock>
    <Styled.OrderedContent>{children}</Styled.OrderedContent>
  </Styled.OrderedLi>
)
