import styled from 'styled-components'
import { theme } from '@shared/config'
import { TTextStyledProps } from './types'
import { generateBreakpointStyles } from './lib'

const Container = styled.div<TTextStyledProps>`
  white-space: pre-line;
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
  font-size: ${({ fontSizeMob }) => `${fontSizeMob || theme.fonts.fontSizes.default}px`};
  line-height: ${({ lineHeightMob, fontSizeMob }) =>
    `${lineHeightMob || fontSizeMob || theme.fonts.lineHeights.default}px`};
  font-weight: ${({ fontWeightMob }) => fontWeightMob || theme.fonts.fontWeights.regular};
  font-family: ${({ fontFamily = 'inherit' }) => fontFamily};
  strong {
    font-weight: ${theme.fonts.fontWeights.bold};
  }

  b {
    font-weight: ${theme.fonts.fontWeights.medium};
  }

  ${({ marginBottomMob }) => marginBottomMob && `margin-bottom: ${marginBottomMob}px`};
  ${({ marginTopMob }) => marginTopMob && `margin-top: ${marginTopMob}px`};

  ${({ fontSizeSm, lineHeightSm, fontWeightSm, marginBottomSm, marginTopSm, lineHeightMob }) =>
    (fontSizeSm || lineHeightSm || fontWeightSm || marginBottomSm || marginTopSm) &&
    generateBreakpointStyles({
      breakpoint: theme.breakpoints.sm,
      fontSize: fontSizeSm,
      lineHeight: lineHeightSm || lineHeightMob,
      fontWeight: fontWeightSm,
      marginBottom: marginBottomSm,
      marginTop: marginTopSm,
    })}

  ${({ fontSizeTablet, lineHeightTablet, fontWeightTablet, marginBottomTablet, marginTopTablet, lineHeightMob }) =>
    (fontSizeTablet || lineHeightTablet || fontWeightTablet || marginBottomTablet || marginTopTablet) &&
    generateBreakpointStyles({
      breakpoint: theme.breakpoints.tablet,
      fontSize: fontSizeTablet,
      lineHeight: lineHeightTablet || lineHeightMob,
      fontWeight: fontWeightTablet,
      marginBottom: marginBottomTablet,
      marginTop: marginTopTablet,
    })}

  ${({ fontSizeMd, lineHeightMd, fontWeightMd, marginBottomMd, marginTopMd, lineHeightMob }) =>
    (fontSizeMd || lineHeightMd || fontWeightMd || marginBottomMd || marginTopMd) &&
    generateBreakpointStyles({
      breakpoint: theme.breakpoints.md,
      fontSize: fontSizeMd,
      lineHeight: lineHeightMd || lineHeightMob,
      fontWeight: fontWeightMd,
      marginBottom: marginBottomMd,
      marginTop: marginTopMd,
    })}

  ${({ fontSizeLg, lineHeightLg, fontWeightLg, marginBottomLg, marginTopLg, lineHeightMob }) =>
    (fontSizeLg || lineHeightLg || fontWeightLg || marginBottomLg || marginTopLg) &&
    generateBreakpointStyles({
      breakpoint: theme.breakpoints.lg,
      fontSize: fontSizeLg,
      lineHeight: lineHeightLg || lineHeightMob,
      fontWeight: fontWeightLg,
      marginBottom: marginBottomLg,
      marginTop: marginTopLg,
    })}
`

export const Styled = {
  Container,
}
