import React, { Fragment } from 'react'
import { Text } from '@shared/ui'
import { theme } from '@shared/config'
import { ListBulletUnordered } from './ListBulletUnordered'
import { ListBulletOrdered } from './ListBulletOrdered'
import { TList } from '../types'
import { getTextStyle } from '../config'
import { Container } from './Container'

const getChildren = ({
  subElements,
  size,
  sizemob,
  text,
}: {
  subElements?: TList
  text?: string
} & Pick<TList, 'sizemob' | 'size'>) => (
  <>
    <Text {...getTextStyle(size, sizemob)}>{text}</Text>
    {subElements && <List {...subElements} />}
  </>
)

export const List = ({
  type = 'ul',
  bulletType = 'empty',
  color = theme.colors.blueDark,
  bulletColor,
  list,
  size = 'md',
  sizemob,
}: TList) => (
  <Container type={type} color={color}>
    {list.map(({ text, subElements }, index) => (
      <Fragment key={text.substring(0, 100)}>
        {type === 'ol' ? (
          <ListBulletOrdered index={index + 1} size={size} sizemob={sizemob}>
            {getChildren({ subElements, size, sizemob, text })}
          </ListBulletOrdered>
        ) : (
          <ListBulletUnordered bulletType={bulletType} bulletColor={bulletColor || color} size={size} sizemob={sizemob}>
            {getChildren({ subElements, size, sizemob, text })}
          </ListBulletUnordered>
        )}
      </Fragment>
    ))}
  </Container>
)
