import styled, { css } from 'styled-components'
import { theme } from '@shared/config'
import { configTextStyle } from '@entities/List/config'
import { TBulletType, TSize } from '../../types'

const bulletGaps: Record<TSize, string> = {
  sm: `${theme.spacings.xxs}px ${theme.spacings.xs2}px 1fr`,
  md: `${theme.spacings.xxs1}px ${theme.spacings.xs1}px 1fr`,
  lg: `${theme.spacings.xs}px ${theme.spacings.xs3}px 1fr`,
} as const

const bulletSizeMap: Record<TSize, string> = {
  sm: `${theme.spacings.xxs}px`,
  md: `${theme.spacings.xxs1}px`,
  lg: `${theme.spacings.xs}px`,
} as const

const bulletPosition: Record<TSize, string> = {
  lg: `${theme.spacings.xs}px`,
  md: `${theme.spacings.xs}px`,
  sm: `${theme.spacings.xs}px`,
} as const

const bulletTextStyle = css<{ size: TSize; sizemob; bulletColor?: TSize }>`
  position: absolute;
  top: 0;
  left: 0;
  ${({ sizemob, size, bulletColor }) => css`
    color: ${bulletColor};
    font-size: ${configTextStyle[sizemob || size].fontSize}px;

    ${sizemob &&
    css`
      @media (min-width: ${theme.breakpoints.sm}px) {
        font-size: ${configTextStyle[size].fontSize}px;
      }
    `}
  `}
`

const UnorderedWrapper = styled.li<{
  size: TSize
  sizemob: TSize
}>`
  display: grid;
  grid-template-rows: 1fr;
  margin: 0;
  align-self: center;
  ${({ sizemob, size }) => css`
    grid-template-columns: ${bulletGaps[sizemob || size]};

    ${sizemob &&
    css`
      @media (min-width: ${theme.breakpoints.sm}px) {
        grid-template-columns: ${bulletGaps[size]};
      }
    `}
  `}
`

const getBulletStyle = ({ size, sizemob, bulletColor }: { size: TSize; sizemob: TSize; bulletColor: string }) => ({
  empty: css``,
  default: css`
    background-color: ${bulletColor};
    border-radius: 50%;

    width: ${bulletSizeMap[sizemob || size]};
    height: ${bulletSizeMap[sizemob || size]};
    margin-top: ${bulletPosition[sizemob || size]};

    ${sizemob &&
    css`
      @media (min-width: ${theme.breakpoints.sm}px) {
        width: ${bulletSizeMap[sizemob]};
        height: ${bulletSizeMap[sizemob]};
        margin-top: ${bulletPosition[sizemob]};
      }
    `}
  `,
  check: css`
    &:before {
      content: '✔';
      ${bulletTextStyle}
    }
  `,
  dash: css`
    &:before {
      content: '–';
      ${bulletTextStyle}
    }
  `,
})

const UnorderedIcon = styled.span<{
  size: TSize
  sizemob: TSize
  bulletColor: string
  bulletType: TBulletType
}>`
  position: relative;
  ${({ bulletType, bulletColor, size, sizemob }) => getBulletStyle({ bulletColor, size, sizemob })[bulletType]}
`
const UnorderedBulletWrapper = styled.div`
  display: grid;
  grid-column: 1;
  width: 100%;
  align-self: start;
`

const UnorderedContent = styled.div`
  grid-column: 3;
  grid-row: 1/3;
`

export const Styled = {
  UnorderedWrapper,
  UnorderedIcon,
  UnorderedBulletWrapper,
  UnorderedContent,
}
