import React, { ReactNode } from 'react'
import { TListType } from '../../types'

export const Container = ({ type, children, color }: { type: TListType; color: string; children: ReactNode }) => {
  const style = {
    color,
  }

  if (type === 'ol') {
    return <ol style={style}>{children}</ol>
  }

  return <ul style={style}>{children}</ul>
}
