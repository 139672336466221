export const spacings = {
  xxs: 4,
  xxs1: 6,
  xs: 8,
  xs1: 10,
  xs2: 12,
  xs3: 16,
  xs4: 18,
  sm: 20,
  sm1: 22,
  sm2: 24,
  sm3: 28,
  md: 30,
  md2: 32,
  md3: 36,
  md4: 40,
  md5: 48,
  md6: 50,
  lg0: 56,
  lg1: 58,
  lg2: 60,
  lg3: 64,
  xl: 76,
  xl2: 80,
  xl3: 100,
  xl4: 128,
  xl5: 120, // поменять местами
} as const
